import { IPageParams } from "../@types/tables";
import { INCREASE_BY } from "../api/graphql/common";
import { makeVar } from "@apollo/client";

const searchParams = new URLSearchParams(window.location.search);
export const initialSkuPageParams: IPageParams = {
  sortField: "id",
  sortOrder: "ASC",
  take: INCREASE_BY,
  skip: 0,
};

export const skuPageParams = makeVar<IPageParams>({
  ...initialSkuPageParams,
  sortField: searchParams.get("sortField") || "id",
  take: +searchParams.get("take") || INCREASE_BY,
  skip: +searchParams.get("skip") || 0,
  sortOrder: searchParams.get("sortOrder")?.toUpperCase() || "ASC",
});

export const activePayloadVar = makeVar<any[]>([]);
export const favoriteSkus = makeVar<{ [p: string]: string[] }>({});
