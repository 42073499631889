import { FC, useCallback, useMemo } from "react";
import { ISkuPosition } from "../../@types/chart";
import { useReactiveVar } from "@apollo/client";
import { skuToProductLink } from "../../helpers/ozon";
import PinIcon from "../../assets/icons/pin.svg?react";
import { activePayloadVar } from "../../store/skus.store";

const PromotionBuilder: FC<{
  item: ISkuPosition["competitorItemsByCity"][0]["value"][0];
}> = ({ item }) => {
  if (["TOP_PLACEMENT", "TEMPLATES"].includes(item.promotion.type)) {
    return (
      <>
        <div>
          <b>Ставка:</b> {item.promotion.value} {item.promotion.subValue}
        </div>
      </>
    );
  }
  if (item.promotion.type === "SEARCH_PROMOTION") {
    return (
      <>
        <div>
          <b>Процентная ставка:</b> {item.promotion.value}
        </div>
        <div>
          <b>Cумма:</b> {item.promotion.subValue}
        </div>
      </>
    );
  }

  return null;
};

export const ChartTooltipCompetitors = ({
  active,
  payload,
  skuToInfo,
  productInfo,
  skuItem,
}: any) => {
  const activePayload = useReactiveVar(activePayloadVar);
  const getSkuInfo = useCallback(
    (date: string, sku: string) => skuToInfo?.[date + "__" + sku],
    [skuToInfo],
  );

  const { date, skus } = useMemo(() => {
    const item = payload[0]?.payload || {};
    const date = item?.date;
    const skus = [];

    for (let i = 0; i < payload.length; i++) {
      const elem = {
        sku: payload[i].name,
        color: payload[i].color,
        position: getSkuInfo(date, payload[i].name)?.position,
        isCurrentSKU: +payload[i].name === +productInfo?.sku,
        isFriendly: skuItem?.friendlySkus?.includes(payload[i].name),
        isCompetitor: skuItem?.competitorSkus?.includes(payload[i].name),
      };
      skus.push(elem);
    }

    return { date, skus: [...skus].sort((a, b) => a.position - b.position) };
  }, [
    getSkuInfo,
    payload,
    productInfo?.sku,
    skuItem?.competitorSkus,
    skuItem?.friendlySkus,
  ]);

  if (active && payload && payload.length) {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-grey900 rounded-8 p-8 shadow-2xl"
      >
        {!!activePayload.length && (
          <button
            onClick={() => activePayloadVar([])}
            className="inline-flex absolute top-2 right-8 text-primary700 w-20 h-20"
          >
            <PinIcon />
          </button>
        )}
        {date && (
          <div className="custom-chart-tooltip-date !text-white">{date}</div>
        )}
        <div className="custom-chart-tooltip !bg-transparent !shadow-none border-none !p-0 !grid grid-cols-2 !gap-8">
          {skus.length &&
            skus.map((i: any) => {
              const info:
                | ISkuPosition["competitorItemsByCity"][0]["value"][0]
                | undefined = getSkuInfo(date, i.sku);
              const color = i.isFriendly
                ? "rgba(var(--primary700))"
                : i.isCompetitor
                  ? "rgba(var(--danger700)"
                  : i.color;

              return (
                <div
                  style={{ color }}
                  key={i.dataKey}
                  className="custom-chart-tooltip-item relative"
                >
                  <div
                    className={`custom-chart-tooltip-item-content ${i.isCurrentSKU ? "ml-10" : ""}`}
                  >
                    <div>
                      <b>Магазин:</b> {info?.itemInfo?.sellerName}
                    </div>
                    <div>
                      <b>Позиция:</b> {info?.position}
                    </div>
                    <div>
                      <b>SKU:</b>{" "}
                      <a
                        className="text-xs underline"
                        target="_blank"
                        rel="noreferrer"
                        href={skuToProductLink(info?.itemInfo?.sku)}
                      >
                        {info?.itemInfo?.sku}
                      </a>
                    </div>
                    {i.isCurrentSKU && (
                      <span className="flex h-[8px] w-[8px] absolute top-0 left-0">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-[8px] w-[8px] bg-green-500"></span>
                      </span>
                    )}
                    {info && <PromotionBuilder item={info} />}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  return null;
};
